import { Flex, IconWrapper, PageCard } from '../../styles/CommonStyles'
import ListLayout from '../../components/layout/main/ListLayout'
import { useState } from 'react'
import Tooltip from '../../components/common/utils/Tooltip'
import { AddIcon } from '../../styles/main/setting'
import { useNavigate } from 'react-router-dom'
import Tabbar from '../../components/common/tabs/Tabbar'
import AllUsers from './AllUsers'
import { routes } from '../../core/constants/routes'
import AgentUsers from './AgentUsers'

const Users = () => {
	const navigate = useNavigate()

	const [activeTab, setActiveTab] = useState(1)

	const onAddUser = () => {
		navigate(routes.addUser)
	}

	return (
		<ListLayout>
			<PageCard>
				<Flex fw justify={'flex-end'}>
					<Tabbar
						active={activeTab}
						tabs={['all-users', 'agent-users']}
						onTabClicked={(idx) => setActiveTab(idx)}
					/>
					<Tooltip content={'add-user'}>
						<IconWrapper onClick={onAddUser}>
							<AddIcon size={24} />
						</IconWrapper>
					</Tooltip>
				</Flex>
				{activeTab === 1 ? <AllUsers /> : <AgentUsers />}
			</PageCard>
		</ListLayout>
	)
}

export default Users
