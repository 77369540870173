import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	MdDashboard,
	MdGeneratingTokens,
	MdOutlineFeed,
	MdSettingsInputComponent,
} from 'react-icons/md'
import { CgProfile, CgFeed, CgClipboard } from 'react-icons/cg'
import { FaWallet, FaRobot } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { TbAffiliate } from 'react-icons/tb'
import { BsFilesAlt } from 'react-icons/bs'
import { GiTwoCoins } from 'react-icons/gi'
import { BiCurrentLocation } from 'react-icons/bi'
import { SiGoogletagmanager, SiBlockchaindotcom } from 'react-icons/si'
import { RiBankCard2Fill, RiListUnordered, RiCopperCoinFill, RiBankFill } from 'react-icons/ri'
import { useAclContext } from '../../contexts/acl'
import { useMainContext } from '../../contexts/main'
import { useWindowSize } from '../common/useWindowSize'
import { MOBILE_SIZE } from '../../constants/common'
import { GoChecklist, GoScreenFull } from 'react-icons/go'
import { FaTimeline } from 'react-icons/fa6'
import { IoMdSettings } from 'react-icons/io'

const useSidebar = () => {
	const { width } = useWindowSize()
	const navigate = useNavigate()
	const { onSidebarChange } = useMainContext()

	const [routes, setRoutes] = useState(getSideRoutes())

	const onRouteClicked = (idx) => {
		if (routes[idx].items) {
			const newRoutes = [...routes]
			routes[idx].open = !routes[idx].open
			setRoutes(newRoutes)
		} else if (width < MOBILE_SIZE) {
			onSidebarChange()
		}
		navigate(routes[idx].path)
	}

	const onSubRouteClicked = (subrouteIndex, itemIndex) => {
		const items = routes[subrouteIndex]
		const route = items.items[itemIndex]
		navigate(route.path)
		if (width < MOBILE_SIZE) {
			onSidebarChange()
		}
	}

	return {
		routes,
		onRouteClicked,
		onSubRouteClicked,
	}
}

const getSideRoutes = () => {
	const routes = [
		{ title: 'profile', path: '/profile', icon: CgProfile, show: true },
		{ title: 'dashboard', path: '/dashboard', icon: MdDashboard, show: true },
		{ title: 'users', path: '/users', icon: HiUserGroup, show: true },
		{
			title: 'medical',
			items: [
				{ title: 'specializations', path: '/medical/specializations', icon: CgFeed, show: true },
				{ title: 'doctors', path: '/medical/doctors', icon: FaRobot, show: true },
				{ title: 'time_slots', path: '/medical/time_slots', icon: FaTimeline, show: true },
			],
			open: false,
			show: true,
			path: '/medical',
		},
		{
			title: 'reports',
			items: [
				{ title: 'transactions', path: '/reports/transactions', icon: RiListUnordered, show: true },
				{ title: 'staking', path: '/reports/staking', icon: RiCopperCoinFill, show: true },
			],
			open: false,
			show: true,
			path: '/reports',
		},
		{
			title: 'setting',
			items: [
				{ title: 'general', path: '/setting/general', icon: IoMdSettings, show: true },
				{ title: 'staking', path: '/setting/staking', icon: RiCopperCoinFill, show: true },
				{ title: 'doctor', path: '/setting/doctor', icon: SiBlockchaindotcom, show: true },
			],
			open: false,
			show: true,
			path: '/setting',
		},
	]

	return routes
}

export default useSidebar
