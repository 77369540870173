import { useMutation, useQuery } from 'react-query'
import { normalFetch } from '../../../fetch-api/get'
import { patchApi } from '../../../fetch-api/put'
import { useQueryContext } from '../../../../contexts/query'

export const generalKeys = {
	getGeneralSettings: 'getGeneralSettings',
	setGeneralSettings: 'setGeneralSettings',
}

export const useGetGeneralSettings = () => {
	return useQuery({
		queryFn: () => normalFetch('admin/settings'),
		queryKey: [generalKeys.getGeneralSettings],
		select: (res) => res?.data?.data,
	})
}

export const useUpdateGeneralSettings = (onSuccess) => {
	const { queryClient, setToast } = useQueryContext()

	return useMutation({
		mutationKey: [generalKeys.setGeneralSettings],
		mutationFn: (payload) => patchApi(payload, 'admin/settings'),
		onSuccess: () => {
			queryClient.invalidateQueries([generalKeys.getGeneralSettings])
			setToast({
				message: 'operation-success',
				show: true,
			})
			onSuccess()
		},
	})
}
