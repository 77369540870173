import { useUsersQuery } from '../../core/services/react-query/user'
import { CacheKeys, userFilterOptions } from '../../core/constants/filter'
import FilterLayout from '../../components/layout/filter/FilterLayout'
import { useState } from 'react'
import { useWindowSize } from '../../core/hooks/common/useWindowSize'
import { TABLET_SIZE } from '../../core/constants/common'
import UsersTable from '../../components/main/users/UsersTable'
import RUserTable from '../../components/responsive/main/users/RUserTable'

const AgentUsers = () => {
	const { width } = useWindowSize()
	const [users, setUsers] = useState({ data: null, loading: true })

	const onUserQuery = (res) => {
		setUsers(res)
	}
	const Component = width > TABLET_SIZE ? UsersTable : RUserTable

	return (
		<FilterLayout
			query={useUsersQuery}
			options={userFilterOptions}
			onQuerySuccess={onUserQuery}
			cache={CacheKeys.agent_users}
			extra={{ agent: true }}
		>
			{width && <Component data={users} />}
		</FilterLayout>
	)
}

export default AgentUsers
