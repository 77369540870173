import { ClipLoader } from 'react-spinners'
import ListLayout from '../../../components/layout/main/ListLayout'
import {
	useGetGeneralSettings,
	useUpdateGeneralSettings,
} from '../../../core/services/react-query/setting/general'
import { getMainTheme } from '../../../core/utils/theme'
import Text from '../../../core/utils/Text'
import ModalLayout from '../../../components/layout/main/ModalLayout'
import { useState } from 'react'
import { TbEdit } from 'react-icons/tb'
import Input from '../../../components/common/input/Input'
import { Background } from '../../../styles/CommonStyles'

const General = () => {
	const { data: settings, isLoading: loadingSettings } = useGetGeneralSettings()

	const initInput = { value: '', description: '' }
	const [input, setInput] = useState(initInput)

	const initModal = { show: false, data: null }
	const [modal, setModal] = useState(initModal)
	const openModal = (data) => {
		setModal({ show: true, data })
		setInput({
			value: data.value,
			description: data.description,
		})
	}
	const closeModal = () => {
		setModal(initModal)
		setInput(initInput)
	}

	// update setting
	const { mutate: updateSetting, isLoading: updateSettingLoading } =
		useUpdateGeneralSettings(closeModal)
	const onUpdate = () => {
		updateSetting({
			key: modal.data.key,
			value: input.value,
			description: input.description,
		})
	}

	return (
		<ListLayout>
			<div className={'w-full flex items-stretch gap-8 flex-wrap relative'}>
				{loadingSettings ? (
					<div className={'w-full h-[300px] flex-center cardBg shadow'}>
						<ClipLoader size={20} color={getMainTheme().active} />
					</div>
				) : null}

				{settings?.map((s) => {
					return (
						<div
							key={s.id}
							className={
								'rounded-md p-6 min-w-[300px] cardBg borderMain shadow dark:text-gray-200 text-sm'
							}
						>
							<div className={'text-xs textPrimary flex items-center gap-1'}>
								<span>{s.id}</span>
								<span> </span>
								<span>{s.key}</span>
								<div className={'mt-[-4px] cursor-pointer px-1'} onClick={() => openModal(s)}>
									<TbEdit size={20} />
								</div>
							</div>
							<div className={'bg-slate-500/20 w-full h-[1px] mt-1'} />
							<div className={'mt-3 flex flex-col gap-3'}>
								<div className={'flex items-center gap-2'}>
									<Text className={'textPrimary text-xs'} tid={'valueCol'} />
									<span>{s.value}</span>
								</div>
								<div className={'flex items-center gap-2'}>
									<Text className={'textPrimary text-xs'} tid={'categoryCol'} />
									<span>{s.category}</span>
								</div>
								<div className={'flex flex-col gap-1 max-w-[300px]'}>
									<Text className={'textPrimary text-xs'} tid={'descriptionCol'} />
									<span>{s.description}</span>
								</div>
							</div>
						</div>
					)
				})}
			</div>

			<ModalLayout open={modal.show} width={'500px'} onClose={closeModal}>
				<div className={'text-sm dark:text-white flex items-center gap-1'}>
					<span>{modal?.data?.key}</span>
				</div>
				<div className={'bg-slate-500/20 w-full h-[1px] mt-1'} />
				<div className={'flex flex-col gap-3 mt-5'}>
					<Background bg={'mainBg'}>
						<Input
							value={input.value}
							onInputChange={(v) => setInput((s) => ({ ...s, value: v }))}
							label={'value'}
						/>
						<Input
							value={input.description}
							onInputChange={(v) => setInput((s) => ({ ...s, description: v }))}
							label={'description'}
						/>
					</Background>

					<div
						className={`rounded-md h-[42px] w-full text-black flex-center bg-active cursor-pointer gap-2`}
						onClick={onUpdate}
					>
						{updateSettingLoading ? <ClipLoader size={20} color={'#000'} /> : null}
						<Text tid={'submit'} />
					</div>
				</div>
			</ModalLayout>
		</ListLayout>
	)
}

export default General
